import 'core-js/stable'
import "regenerator-runtime/runtime";
import Vue from 'vue'
import { BootstrapVue, IconsPlugin, BFormFile } from 'bootstrap-vue'
import vSelect from "vue-select";
import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from "./router";
import ActionBarComponent from "./components/ActionBar";
import GYRangeInput from './components/inputs/GYRangeInput';
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";
import { setAccess } from "@/core/services/user.group.access.service";
import { API_LANGUAGE_CHANGED, API_GYAUTH, API_CHECK_PERMISSIONS, API_LOGOUT_RESET } from "@/core/services/api/auth.module";
import { APP_RESET_INTERFACE, APP_GET_LAST_STATE } from "@/core/services/app.state.module";
import { API_TRANSLATIONS_ACTION_LOAD } from "@/core/services/api/translations.module";
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip';
import Popup from '@/components/Popup.vue';
import VueI18n from 'vue-i18n';
import VueMobileDetection from "vue-mobile-detection";
import './scripts/ieClosest.module';
import './scripts/ieEvent.module';


Vue.use(VueI18n)
Vue.component('b-form-file', BFormFile);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component('gy-popup', Popup);
Vue.use(VueCustomTooltip, {
    name: 'VueCustomTooltip',
    color: '#fff',
    background: '#000',
    borderRadius: 6,
    fontWeight: 100
});
Vue.use(VueMobileDetection);

// API service init
ApiService.init();

Vue.config.productionTip = true;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component('gy-popup', Popup);
Vue.component("v-select", vSelect);
Vue.component("action-bar", ActionBarComponent);
Vue.component("gy-range-input", GYRangeInput);

const DEFAULT_TITLE = 'Goodyear Portal';

let _i18n;

i18nService.initI18n(store).then(function(i18n) {
  document.body.classList.remove('start');
  document.body.classList.remove('loading-app');
  _i18n = i18n;
  
  //console.log(i18n);

  Vue.prototype.$trans = function (binding) {
    if(binding) {
      if (i18n.te(binding.toLowerCase())) {
        return i18n.t(binding.toLowerCase());
      } else {
        return (binding);
      }
    } else {
      return binding;
    }
  };

  Vue.prototype.$numberWithCommas = function (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  window.app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#vuejs-app');



}).catch(()=>{
  // reset config to initial state
  //store.dispatch(APP_RESET_INTERFACE);
  document.getElementById('loading-message').innerHTML = "Could not connect to server.<br />Please try again later.";
  document.body.classList.remove('start');
});

router.afterEach((to, from) => {
  if(to.path.includes("/admin") && (!store.getters.AppGetUser.UserAccessSecurities.includes("LGADMIN") || (store.getters.AppGetUser.Group != "GYR" && store.getters.AppGetUser.Group != "SSC"))) {
    const showPermissionsPopupEvent = new window.CustomEvent('showPermissionsPopupEvent');
    document.dispatchEvent(showPermissionsPopupEvent);
    setAccess(false);
  }

  //change header icon
  const headerChangeIconEvent = new window.CustomEvent('headerChangeIconEvent');
  document.dispatchEvent(headerChangeIconEvent);

  //space event to tabindex
  /*var checkbox_labels = document.querySelectorAll('.material-checkbox-label');
  let prev;
  for(var index in checkbox_labels) {
    if (checkbox_labels.hasOwnProperty(index)) {
      console.log(checkbox_labels[index]);
      checkbox_labels[index].onkeyup = function(e){
        if(e.keyCode == 32){
          //your code
          console.log(e.target.previousSibling);
          prev = e.target.previousSibling;
          prev.select();
        }
      };
    }
  }*/ 
    if (Object.prototype.hasOwnProperty.call(to.query,'user')) {
        history.replaceState("", document.title, window.location.pathname);
    }
  Vue.nextTick(() => {
    if (to.meta.title != undefined) {
      document.title = to.meta.title + " - " + DEFAULT_TITLE;
    } else {
      document.title = DEFAULT_TITLE;
    }  
  });
});

router.beforeEach((to, from, next) => {
  Promise.all([store.dispatch(APP_GET_LAST_STATE)]).catch(()=>{});;

  if (Object.prototype.hasOwnProperty.call(to.query, 'lang')) {
    let selectedLanguage = to.query.lang;
    // let i18n = new VueI18n();
    Promise.all([store.dispatch(API_TRANSLATIONS_ACTION_LOAD, selectedLanguage)])
      .then(() => {
        _i18n.setLocaleMessage(selectedLanguage, store.getters.getTranslationsMessage);
        _i18n.locale = selectedLanguage;
        window.localStorage.setItem("USER_LANGUAGE", selectedLanguage);
        const langChooseEvent = new window.CustomEvent('langChooseEvent');
        document.dispatchEvent(langChooseEvent);
      })
      .catch(()=>{
        // reset config to initial state
        store.dispatch(APP_RESET_INTERFACE).then(() => {}).catch(() => {});
        router.push({ name: "Login", query: to.fullPath == "/" ? null : { returnUrl: to.fullPath } });
      });
  }
  if(to.name == "Login") {
    store.dispatch(API_LOGOUT_RESET)
    .then(() => {
      store.dispatch(API_LANGUAGE_CHANGED)
      .then(() => {})
      .catch((responce) => {
          console.log('error occured in Promise.all([store.dispatch(API_LANGUAGE_CHANGED)])   main.js');
          console.log(responce);
          store.dispatch(APP_RESET_INTERFACE).then(() => { }).catch(() => { });
      });
    })
    .catch(() => {});
  }
  else if (to.name != "Logout") {
    store.dispatch(API_LANGUAGE_CHANGED)
    .then(() => {})
    .catch((responce) => {
        console.log('error occured in Promise.all([store.dispatch(API_LANGUAGE_CHANGED)])   main.js');
        console.log(responce);
        store.dispatch(APP_RESET_INTERFACE).then(() => { }).catch(() => { });
    });
  }

    if (Object.prototype.hasOwnProperty.call(to.query, 'user')) {
        
    Promise.all([store.dispatch(API_GYAUTH, {Token: to.query.user})])
      .then(()=> {
        if (Object.prototype.hasOwnProperty.call(to, 'meta') && Object.prototype.hasOwnProperty.call(to.meta, 'requiresAuth')) {
    
          if (to.meta.requiresAuth) {
            // store.dispatch(API_LANGUAGE_CHANGED)
            // .catch(() => {
            //   console.log('error occured in Promise.all([store.dispatch(API_LANGUAGE_CHANGED)])   main.js');
            // });
            // Ensure we checked auth before each page load.
            store.dispatch(API_CHECK_PERMISSIONS, { path: to.fullPath })
            .then((isAccess) => {
                next();

                let return_url_params = new URLSearchParams();
                let url = to.query;
                for (let index_param in url) {
                    if (index_param == 'user') continue;
                    return_url_params.set(index_param, url[index_param]);
                }
                
                if (return_url_params.toString()) {
                  location.search = return_url_params.toString();
                }
            })
            .catch(() => {
              router.push({ name: "Login", query: to.fullPath == "/" ? null : { returnUrl: to.fullPath } });   
            }); 
          } else { next(); }
          
        } else { next(); }
      })
      .catch(()=>{
        // reset config to initial state
        store.dispatch(APP_RESET_INTERFACE).then(() => {}).catch(() => {});
        router.push({ name: "Login", query: to.fullPath == "/" ? null : { returnUrl: to.fullPath } });
      });
  }
  else if (Object.prototype.hasOwnProperty.call(to, 'meta') && Object.prototype.hasOwnProperty.call(to.meta, 'requiresAuth')) {

    if (to.meta.requiresAuth) {
      
      store.dispatch(API_CHECK_PERMISSIONS, { path: to.fullPath })
      .then((isAccess) => {
        // if(isAccess) {
          next();
        //   store.dispatch(API_VERIFY_AUTH)
        //   .then(next)
        //   .catch(() => {
              
        //   });   
        // }
        // else {
        //   const showPermissionsPopupEvent = new Event('showPermissionsPopupEvent', {
        //     bubbles: true,
        //     cancelable: true,
        //     composed: false
        //   })
        //   document.dispatchEvent(showPermissionsPopupEvent);
        // }
      }).catch(()=>{
        // reset config to initial state
        store.dispatch(APP_RESET_INTERFACE).catch(() => {});
        router.push({ name: "Login", query: to.fullPath == "/" ? null : { returnUrl: to.fullPath } });
      });
      
    } else { next(); } //no auth required

  } else { next();  } //no auth required

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

});

(function() {
  
  function decimalAdjust(type, value, exp) {
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  
  if (!Math.round10) {
    Math.round10 = function(value, exp) {
      return decimalAdjust('round', value, exp);
    };
  }
  
  if (!Math.floor10) {
    Math.floor10 = function(value, exp) {
      return decimalAdjust('floor', value, exp);
    };
  }
  
  if (!Math.ceil10) {
    Math.ceil10 = function(value, exp) {
      return decimalAdjust('ceil', value, exp);
    };
  }
})();